import { version } from '../../../../package.json';

export const environment = {
  production: true,
  AUTH0_DOMAIN: 'auth0.tiime.fr',
  AUTH0_CLIENT_ID: 'yzIvgseLZ42FSJViygQmap4F5Z0fWQcN',
  AUTH0_REALM: 'Chronos-dev',
  AUTH0_AUDIENCE: 'https://chronos/',
  AUTH0_WHITE_DOMAINS: ['chronos-review-indigo.preprod.tiime.tech'],
  API_URL: 'https://chronos-review-indigo.preprod.tiime.tech',
  APPS_URL: 'https://apps.chronos-review-indigo.preprod.tiime.tech',
  EXPERT_URL: 'https://expert.chronos-review-indigo.preprod.tiime.tech',
  VERSION: version
};
